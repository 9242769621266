<template>
  <div class="i_bottom">
    <!-- <div>
      <a
        href="#"
        target="_blank"
        class="copyright"
        :style="{ color: textColor }"
      > Copyright © 2018~2023 西安纵横资讯有限责任公司 版权所有</a>
    </div> -->

   <div v-if="isPC" class="copyright" :style="{ color: textColor }">
    <a href="https://beian.miit.gov.cn" target="_blank" :style="{ color: textColor }">工业和信息化部备案管理系统网站 陕ICP备2024050240号</a>
    <!-- &nbsp;|&nbsp; <a href="http://www.xazhzx.com/" target="_blank" :style="{ color: textColor }">技术支持：西安纵横资讯有限责任公司</a> -->
   </div>
   <div v-if="!isPC" class="copyright" :style="{ color: textColor }">
     <div><a href="https://beian.miit.gov.cn" target="_blank" :style="{ color: textColor }">工业和信息化部备案管理系统网站 陕ICP备2024050240号</a></div>
     <!-- <div><a href="http://www.xazhzx.com/" target="_blank" :style="{ color: textColor }">技术支持：西安纵横资讯有限责任公司</a></div> -->
   </div>

<!--    <div-->
<!--      v-for="item in submenu"-->
<!--      :key="item.id"-->
<!--      class="copyright"-->
<!--    >-->
<!--     {{ item?.name }}-->
<!--        <span-->
<!--          v-if="item.data"-->
<!--        >&nbsp;|&nbsp;-->
<!--          <a-->
<!--            v-for="child in item?.data"-->
<!--            :key="child?.id"-->
<!--            :href="child.content"-->
<!--            target="_blank"-->
<!--            class="lingke_link"-->
<!--          >{{ child.title }}-->
<!--          -->
<!--          </a>-->
<!--        </span>-->
<!--    </div>-->
  </div>
</template>
<script>
import { carouselList, getArticles } from '@/api/main'

export default {
  name: 'Bottom',
   props: {
      textColor: {
        type: String,
        default: '#0c0c0c' // 默认颜色
      }
    },
  // async asyncData(context) {
  //   try {
  //     debugger
  //     const dataObj = {}
  //     // 专家智库内容
  //     // 1.备案号
  //     dataObj.bah = await getArticles(context.store.state.itemcate['dbnr'])
  //
  //     // 2.安全证书标签
  //     dataObj.aqzs = await getArticles(context.store.state.itemcate['aqzsjc'])
  //
  //     // 3.电话
  //     dataObj.dh = await getArticles(context.store.state.itemcate['lxdh'])
  //
  //     // 4.二维码
  //     dataObj.ewm = await getArticles(context.store.state.itemcate['ewm'])
  //     console.log('尾部信息', dataObj)
  //     return dataObj
  //   } catch (e) {
  //     context.error({ message: 'data no found', statusCode: 404 })
  //   }
  // },
  data() {
    return {
      isPC: false,
      service: this.$store.state.websiteInfo || {},
      submenu: []
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  mounted() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.getBottomInfo()
  },
  methods: {
    checkDevice() {
      if (typeof window !== 'undefined') {
        this.isPC = window.innerWidth > 768 // 根据需要调整阈值
      }
    },
    async getBottomInfo() {
      try {
        console.log('this.$store.state.itemcate', this.$store.state)
        const node = this.$store.state.navList.find(item => {
          return item.mainColumn.code === 'dbnr'
        })

        if (node) {
          this.submenu = node.subColumn
          console.log('this.$store.state.itemcate', this.submenu)

          const reqArr = []
          for (let i = 0; i < this.submenu.length; i++) {
            reqArr.push(new Promise((resolve, reject) => {
              getArticles(this.$store.state.itemcate[this.submenu[i].code]).then((res) => {
                this.submenu[i].data = res || []
                resolve({
                  ...this.submenu[i],
                  data: res || []
                })
              }).catch(() => {
                reject()
              })
            }))
          }

          // eslint-disable-next-line new-cap
          new Promise.all(reqArr).then((res) => {
            console.log('resss', res)
          })
          // // 专家智库内容
          // // 1.备案号
          // this.data.bah = await getArticles(this.$store.state.itemcate['bah'])
          //
          // // 2.安全证书标签
          // this.data.aqzs = await getArticles(this.$store.state.itemcate['aqzsjc'])
          //
          // // 3.电话
          // this.data.dh = await getArticles(this.$store.state.itemcate['lxdh'])
          //
          // // 4.二维码
          // this.data.ewm = await getArticles(this.$store.state.itemcate['ewm'])
          // console.log('尾部信息', data)
        }
      } catch (e) {
      }
    }
  }
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
   @media (max-width: 768px) {
     .copyright {
       flex-direction: column !important;
       font-size: 2.5vw !important;
     }
     // .copyright {
     //   height: 6vw !important;
     //   line-height: 6vw !important;
     // }
   }
.i_bottom {
  background: rgb(255, 255, 255,0 );
  padding: 0.625vw;
  //width: 90vw;
  //padding: 0 1.5vw 0 1.5vw;
  color: #0c0c0c !important;
}

.copyright {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // height: 3vw;
  // line-height: 3vw;
  text-align: center;
  color: #0c0c0c !important;
  font-size: 0.65vw;

  a {
    color: #0c0c0c !important;
  }
}


.icp_num {
  line-height: 1.875vw;
  text-align: center;
  color: #0c0c0c !important;

  .lingke_link {
    color: #0c0c0c !important;
    font-size: 1vw;

    &:hover {
      text-decoration: underline;
    }
  }
}

.prn_icon {
  width: 1vw;
  height: 1vw;
  position: relative;
}
</style>
