const result = {
  // baseUrl: 'https://eduos.roncoo.net/gateway', // 接口地址，本地运行默认读取该配置
  // baseUrl: 'http://1.92.205.121:8099/', // 接口地址，本地运行默认读取该配置
  baseUrl: 'https://www.sxatis.org.cn', // 接口地址，本地运行默认读取该配置
  serverUrl: 'http://127.0.0.1:3000', // 接口地址，本地运行默认读取该配置
  // baseUrl: 'http://127.0.0.1:9100',
  tokenName: 'OSTK', // cookie名称,
  lang: 'i18n_redirected',
  refreshTokenName: 'refresh_token', // 刷新token名称
  routerPrefix: '/'
}
if (process.env.NODE_ENV === 'production') {
  // 生产运行默认读取该配置
  result.baseUrl = 'https://www.sxatis.org.cn'
  result.routerPrefix = '/'
  result.serverUrl = result.serverUrl + result.routerPrefix
}

export default result
